import { tv, VariantProps } from '@/utils/tv'

export const iconCard = tv({
  slots: {
    base: ['flex flex-col', 'w-full h-full', 'overflow-hidden'],
    header: ['h-full'],
    icon: ['flex', 'mb-4', 'h-10 w-10'],
    title: [],
    description: ['mt-3'],
    footer: []
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white',
        icon: 'text-acqua-500',
        description: 'text-gray-40'
      },
      light: {
        base: 'text-black',
        icon: 'text-acqua-900',
        description: 'text-gray-60'
      }
    },
    appearance: {
      ghost: {
        base: '',
        footer: 'mt-6'
      },
      card: {
        base: 'drop-shadow-md rounded-3xl',
        header: 'p-6',
        footer: 'p-6'
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    appearance: 'ghost'
  },
  compoundVariants: [
    {
      theme: 'dark',
      appearance: 'card',
      className: {
        header: 'bg-gray-800',
        footer: 'bg-gray-600'
      }
    },
    {
      theme: 'light',
      appearance: 'card',
      className: {
        header: 'bg-gray-5',
        footer: 'bg-gray-new-5'
      }
    }
  ]
})

export type IconCardVariantProps = VariantProps<typeof iconCard>
