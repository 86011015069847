import { useMemo } from 'react'
import { BlockProps, ReviewCard } from '@/types'
import { ReviewCardVariantProps, reviewCard } from './review-card.variants'

export type useReviewCardProps = BlockProps<ReviewCard, ReviewCardVariantProps>

export const useReviewCard = (props: useReviewCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    data: { cardAppearance = 'ghost', ...restData }
  } = props

  const styles = useMemo(
    () => reviewCard({ theme, appearance: cardAppearance }),
    [theme, cardAppearance]
  )

  const isGhostCard = cardAppearance === 'ghost'

  return {
    theme,
    className,
    sectionName,
    data: {
      cardAppearance,
      ...restData
    },
    styles,
    isGhostCard
  }
}
