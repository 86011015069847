import { useMemo } from 'react'
import { BlockProps, IconCard } from '@/types'
import { ButtonVariantProps } from '../button/button.variants'
import { IconCardVariantProps, iconCard } from './icon-card.variants'

export type useIconCardProps = BlockProps<IconCard, IconCardVariantProps>

export const useIconCard = (props: useIconCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    appearance = 'ghost',
    data
  } = props

  const styles = useMemo(
    () => iconCard({ theme, appearance }),
    [theme, appearance]
  )

  const buttonColor: ButtonVariantProps['color'] =
    theme === 'dark' ? 'acqua' : 'acquaDark'

  return {
    theme,
    className,
    sectionName,
    buttonColor,
    data,
    styles
  }
}
