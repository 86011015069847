'use client'

import { useEffect, useState } from 'react'
import { Media } from '@/types'
import { EventAction } from '@/config/events'
import { dispatchGoogleEvent } from '@/utils/google'
import { VideoCardVariantProps, videoCard } from './video-card.variants'

export type useVideoCardProps = {
  className?: string
  title: string | null
  cover: Media | null
  url: string
} & VideoCardVariantProps

export const useVideoCard = (props: useVideoCardProps) => {
  const { className = '', title, cover, url } = props

  const [modalState, setModalState] = useState(false)
  const [videoId, setVideoId] = useState<string>()
  const [poster, setPoster] = useState<string>()

  const styles = videoCard()

  useEffect(() => {
    const youtubeId = url.match(
      /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/
    )

    if (youtubeId) {
      setVideoId(youtubeId[1])

      if (!cover) {
        setPoster(`https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`)
      }
    }
  }, [cover, url])

  const handleClick = () => {
    if (!!videoId) {
      dispatchGoogleEvent(EventAction.WEBSITE_PLAY, `youtube_${videoId}`)
    }

    setModalState(true)
  }

  return {
    className,
    title,
    url,
    cover,
    poster,
    modalState,
    setModalState,
    videoId,
    handleClick,
    styles
  }
}
