'use client'

import { CarouselMedia } from '@/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { SwiperNav } from '@/components/ui/swiper-nav'
import { MediaCard } from '@/components/ui/media-card'

export type CarouselMediaSwiperProps = {
  className?: string
  navClassName?: string
  sectionName: string
  hasManyCards?: boolean
  data: CarouselMedia
}

const CarouselMediaSwiper = ({
  className = '',
  navClassName = '',
  hasManyCards = false,
  sectionName,
  data
}: CarouselMediaSwiperProps) => {
  const { id, theme, cards = [] } = data

  return (
    <Swiper
      className={className}
      modules={[Navigation]}
      spaceBetween={40}
      slidesPerView={3}
      initialSlide={0}
      simulateTouch={false}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        993: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }}
    >
      {cards.map((card) => (
        <SwiperSlide key={`media-card-${card.id}`} className="!h-auto">
          <MediaCard sectionName={sectionName} theme={theme} data={card} />
        </SwiperSlide>
      ))}
      {hasManyCards && (
        <SwiperNav id={id} className={navClassName} theme={theme} />
      )}
    </Swiper>
  )
}

export default CarouselMediaSwiper
