'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { ReviewCard } from '@/components/ui/review-card'
import { useReviewOrQuoteCarouselProps } from './use-review-or-quote-carousel'

export type ReviewOrQuoteCarouselSwiperProps = Pick<
  useReviewOrQuoteCarouselProps,
  'className' | 'sectionName' | 'data'
>

const ReviewOrQuoteCarouselSwiper = ({
  className = '',
  sectionName,
  data
}: ReviewOrQuoteCarouselSwiperProps) => {
  const { theme, reviews = [] } = data

  return (
    <Swiper
      className={className}
      modules={[Autoplay]}
      simulateTouch={false}
      spaceBetween={40}
      speed={800}
      slidesPerView={3}
      loop={false}
      autoplay={{
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: true
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: true
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
          loop: true
        },
        1199: {
          slidesPerView: 3,
          spaceBetween: 40,
          loop: false
        }
      }}
    >
      {reviews.map((review) => (
        <SwiperSlide key={`review-card-${review.id}`} className="!h-auto">
          <ReviewCard sectionName={sectionName} theme={theme} data={review} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ReviewOrQuoteCarouselSwiper
