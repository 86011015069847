import { tv, VariantProps } from '@/utils/tv'

export const videoCard = tv({
  slots: {
    base: [
      'group',
      'aspect-w-16 aspect-h-9',
      'outline-none',
      'rounded-[20px] overflow-hidden cursor-pointer',
      'focus:outline-acqua-500 outline-offset-2'
    ],
    play: [
      'flex items-center justify-center',
      'group-hover:scale-125',
      'transition-all duration-300',
      'w-16 h-16',
      'rounded-full',
      'absolute z-10 inset-0',
      'm-auto',
      'bg-white'
    ],
    icon: ['w-5 h-5 text-black ml-1'],
    image: ['w-full h-full object-center object-cover'],
    video: ['aspect-h-9 aspect-w-16']
  }
})

export type VideoCardVariantProps = VariantProps<typeof videoCard>
