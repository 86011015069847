import { tv, VariantProps } from '@/utils/tv'

export const mediaCard = tv({
  slots: {
    base: ['group', 'flex flex-col h-full'],
    figure: [],
    imageCard: [
      'flex',
      'aspect-h-9 aspect-w-16',
      'rounded-[20px]',
      'overflow-hidden'
    ],
    image: ['h-full w-full object-cover object-center'],
    caption: ['flex flex-col h-full gap-y-6'],
    header: [],
    preTitle: ['mb-2'],
    title: [],
    titleLink: [
      'transition-colors duration-300',
      'rounded-md',
      'outline-none lg:focus:outline-offset-2 focus:outline-acqua-500'
    ],
    description: ['mt-3'],
    descriptionLink: [
      'rounded-md',
      'outline-none lg:focus:outline-offset-2 focus:outline-acqua-500'
    ],
    footer: ['flex items-center gap-5', 'w-full'],
    author: ['flex flex-col'],
    name: [],
    date: []
  },
  variants: {
    theme: {
      dark: {
        preTitle: ['text-acqua-500'],
        description: ['text-gray-40'],
        titleLink: ['hover:text-acqua-500', 'group-hover:text-acqua-500'],
        date: ['text-gray-40']
      },
      light: {
        preTitle: ['text-acqua-900'],
        description: ['text-gray-60'],
        titleLink: ['hover:text-acqua-900'],
        date: ['text-gray-60']
      }
    },
    layout: {
      vertical: {
        base: ['gap-6'],
        footer: ['mt-auto']
      },
      horizontal: {
        base: ['md:flex-row gap-6 md:gap-12'],
        figure: ['md:w-1/2'],
        caption: ['md:w-1/2'],
        footer: ['md:mt-auto']
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    layout: 'vertical'
  }
})

export type MediaCardVariantProps = VariantProps<typeof mediaCard>

export type MediaCardVariantReturnProps = ReturnType<typeof mediaCard>
