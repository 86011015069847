import { tv, VariantProps } from '@/utils/tv'

export const scrollCard = tv({
  slots: {
    base: [
      'relative',
      'md:p-8',
      'w-full lg:h-[520px]',
      'rounded-3xl lg:sticky'
    ],
    container: ['grid lg:grid-cols-2', 'h-full'],
    caption: [
      'rounded-t-3xl md:rounded-none',
      'flex flex-col justify-center',
      'p-10 sm:p-12 md:p-8 lg:py-20 lg:px-16',
      'md:bg-transparent'
    ],
    title: ['lg:max-w-md'],
    subtitle: ['lg:max-w-md mt-3'],
    description: ['lg:max-w-sm mt-4'],
    cta: ['mt-8 flex-start'],
    media: [
      'flex flex-col justify-center',
      'h-full relative md:bg-transparent'
    ],
    media_container: ['aspect-w-16 aspect-h-9 lg:aspect-none', 'lg:h-full'],
    image: ''
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white border-top-gray-20',
        caption: 'bg-black',
        title: 'text-acqua-500',
        description: 'text-gray-40'
      },
      light: {
        base: 'text-black border-top-gray-20',
        caption: 'bg-white',
        title: 'text-acqua-900',
        description: 'text-gray-60'
      }
    },
    background: {
      cover: {
        image: 'object-cover'
      },
      contain: {
        image: 'object-contain'
      }
    },
    type: {
      invalid: '',
      image: '',
      video: ''
    }
  },
  defaultVariants: {
    background: 'contain',
    theme: 'dark',
    type: 'image'
  },
  compoundVariants: [
    {
      appearance: 'card',
      type: 'video',
      className: {
        media_container: 'md:hidden'
      }
    }
  ]
})

export type ScrollCardVariantProps = VariantProps<typeof scrollCard>
