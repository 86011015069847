import { useMemo } from 'react'
import { BlockProps, Columns2ImageAndContent } from '@/types'
import { columns2ImageAndContent } from './columns2-image-and-content.variants'

export type useColumns2ImageAndContentProps =
  BlockProps<Columns2ImageAndContent>

export const useColumns2ImageAndContent = (
  props: useColumns2ImageAndContentProps
) => {
  const {
    className = '',
    sectionName = '',
    data: { theme = 'light', cards = [], ...restData }
  } = props

  const styles = useMemo(() => columns2ImageAndContent({ theme }), [theme])

  const hasOneCard = cards && cards.length === 1

  return {
    className,
    sectionName,
    hasOneCard,
    data: {
      theme,
      cards,
      ...restData
    },
    styles
  }
}
