import { tv, VariantProps } from '@/utils/tv'

export const reviewCard = tv({
  slots: {
    base: ['flex flex-col'],
    rating: ['flex gap-2 mb-3'],
    star: ['w-4 h-4 shrink-0'],
    body: ['flex flex-col gap-5'],
    quote: ['w-12 h-12 shrink-0'],
    description: [],
    author: ['flex gap-5 mt-auto'],
    authorDetails: ['flex flex-col gap-1 h-full'],
    name: ['not-italic'],
    role: []
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white',
        role: 'text-gray-20'
      },
      light: {
        base: 'text-black',
        role: 'text-gray-60'
      }
    },
    appearance: {
      ghost: {
        body: 'mb-6',
        description: '',
        author: 'flex-row'
      },
      card: {
        base: 'p-10 rounded-3xl h-full',
        body: 'mb-8',
        description: '',
        author: 'flex-col'
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    appearance: 'ghost'
  },
  compoundVariants: [
    {
      theme: 'dark',
      appearance: 'ghost',
      className: {
        name: 'text-white'
      }
    },
    {
      theme: 'light',
      appearance: 'ghost',
      className: {
        name: 'text-black'
      }
    },
    {
      theme: 'dark',
      appearance: 'card',
      className: {
        base: 'bg-gray-800',
        name: 'text-acqua-500',
        quote: 'text-acqua-500'
      }
    },
    {
      theme: 'light',
      appearance: 'card',
      className: {
        base: 'bg-gray-100',
        name: 'text-acqua-900',
        quote: 'text-acqua-900'
      }
    }
  ]
})

export type ReviewCardVariantProps = VariantProps<typeof reviewCard>
